import * as React from 'react';

/**
 * Adds line breaks to all but the last element
 * */
export function renderWithLineBreaks(items: string[]) {
  const length = items.length;
  const lastIndex = length - 1;

  return items.map((line, index) => (
    <>
      {line}
      {length > 1 && index < lastIndex && <br />}
    </>
  ));
}
