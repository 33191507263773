import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import { P, Section, Text } from 'stile-shared/src/components/2020/common/Primitives';
import { Center } from 'stile-shared/src/components/2020/layout/Center';
import { Column } from 'stile-shared/src/components/2020/layout/Column';
import { Row } from 'stile-shared/src/components/2020/layout/Row';
import { Switcher } from 'stile-shared/src/components/2020/layout/Switcher';
import {
  BLOG_URL,
  CONTACT,
  FACEBOOK_URL,
  INSTAGRAM_URL,
  TWITTER_URL,
} from 'stile-shared/src/constants';
import { renderWithLineBreaks } from 'stile-shared/src/utils/renderWithLineBreaks';

import { getPageData } from 'utils/getPageData';

import { theme } from 'styles/theme';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';

export { Head } from 'templates/2020/Head';

function ContactPage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../../stile-shared/assets/images/contact/cover_contact.png"
            alt="A receptionist sits at a desk adorned with green tropical plants"
          />
        }
      />

      <Section>
        <Center max="800px">
          <Switcher space={theme.space.m} limit={2}>
            <Column space={theme.space.m}>
              <Column space={theme.space.xxs}>
                <Text as="h2" bold>
                  Interested in a free trial?
                </Text>
                <P>
                  <Link url="/set-up-trial/">Click here</Link>
                </P>
              </Column>

              <Column space={theme.space.xxs}>
                <Text as="h2" bold>
                  Stile USA
                </Text>
                <P as="address">
                  <Link url={CONTACT.us.address.map.url} openInNewTab>
                    {renderWithLineBreaks(CONTACT.us.address.map.text)}
                  </Link>
                </P>
              </Column>
            </Column>

            <Column space={theme.space.m}>
              <Column space={theme.space.xxs}>
                <Text as="h2" bold>
                  Get in touch
                </Text>

                <Column>
                  <P>
                    Phone: <Link url={CONTACT.us.phone.url}>{CONTACT.us.phone.text}</Link>
                  </P>
                  {/* <P>
                    International:{' '}
                    <Link url={CONTACT.international.phone.url}>
                      {CONTACT.international.phone.text}
                    </Link>
                  </P> */}
                  <P>
                    Email: <Link url={CONTACT.us.email.url}>{CONTACT.us.email.text}</Link>
                  </P>
                </Column>
              </Column>

              <Column space={theme.space.xxs}>
                <Text as="h2" bold>
                  Social media
                </Text>
                <Row justify="flex-start">
                  <Link url={FACEBOOK_URL}>Facebook</Link>
                  <Link url={INSTAGRAM_URL}>Instagram</Link>
                  <Link url={TWITTER_URL}>Twitter</Link>
                  <Link url={BLOG_URL}>Blog</Link>
                </Row>
              </Column>
            </Column>
          </Switcher>
        </Center>
      </Section>

      <CtaBlock />
    </PageLayout>
  );
}

export default ContactPage;
